
import Button from "@/dsComponents/buttons/Button.vue";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import { Action } from "vuex-class";
import { StoreAction } from "@/types";
import moment from "moment-timezone";
import Tooltip from "@/dsComponents/Tooltip/Tooltip.vue";

interface ITotalStatistics {
    activeWorkers: number;
    inactiveWorkers: number;
    workersHiredByWeek: number;
    workersHiredByWeekFromWizim: number;
    WorkersUseApp: number;
    download_rate: number;
    orders_created: number;
    candidateCreated: number;
    candidateContacted: number;
    candidateMeet: number;
    candidateIntegrated: number;
    candidateRefused: number;
}

@Component({
    name: "Statistics",
    components: { Button, Tooltip },
})
export default class Statistics extends mixins(TitleManager) {
    pageTitle = <string>this.$t("page.supervisor.statistics.title");
    loading = false;
    statistics: any = [];
    totalStatisticsInit = {
        activeWorkers: 0,
        inactiveWorkers: 0,
        workersHiredByWeek: 0,
        workersHiredByWeekFromWizim: 0,
        WorkersUseApp: 0,
        download_rate: 0,
        orders_created: 0,
        candidateCreated: 0,
        candidateContacted: 0,
        candidateMeet: 0,
        candidateIntegrated: 0,
        candidateRefused: 0,
    };
    totalStatistics: ITotalStatistics = this.totalStatisticsInit;
    filters: any = {
        week: moment().subtract(1, "week").startOf("week").valueOf(),
    };
    opened = false;
    timeout: any = 0;
    $refs!: any;
    innerValue: string = "moduleCommand";

    @Action("actions/loadStatistics") loadStatistics!: StoreAction;
    @Action("actions/downloadStatistics") downloadStatistics!: StoreAction;

    get theRange() {
        const fromDate = moment(this.filters.week);
        const toDate = moment(this.filters.week).endOf("week");

        return this.formattedRange({ from: fromDate, to: toDate });
    }

    get ranges() {
        const ranges = [];
        const limit = 20;

        const fromDate = moment(this.filters.week).subtract(10, "week").startOf("week");
        const toDate = moment(this.filters.week).subtract(10, "week").endOf("week");

        for (let i = 0; i < limit; ++i) {
            const newFrom = fromDate.clone().add(i, "week").startOf("week");
            const newTo = toDate.clone().add(i, "week").endOf("week");
            ranges.push({
                name: this.formattedRange({ from: newFrom, to: newTo }),
                from: newFrom,
                to: newTo,
            });
        }

        return ranges;
    }
    get isModuleCommand() {
        return this.innerValue === "moduleCommand";
    }

    get isModuleAts() {
        return this.innerValue === "moduleAts";
    }

    async init() {
        this.loading = true;
        try {
            this.statistics = await this.loadStatistics(this.filters);

            // Sums up all categories from this.statistics
            this.totalStatistics = this.statistics.reduce((acc: ITotalStatistics, obj: any) => {
                return {
                    activeWorkers: acc.activeWorkers + (obj.activity.activeWorkers || 0),
                    inactiveWorkers: acc.inactiveWorkers + (obj.activity.inactiveWorkers || 0),
                    workersHiredByWeek: acc.workersHiredByWeek + (obj.activity.workersHiredByWeek?.count || 0),
                    workersHiredByWeekFromWizim: acc.workersHiredByWeekFromWizim + (obj.activity.workersHiredByWeekFromWizim?.count || 0),
                    WorkersUseApp: acc.WorkersUseApp + (obj.activity.WorkersUseApp || 0),
                    download_rate: acc.download_rate + (obj.activity.download_rate?.count || 0),
                    orders_created: acc.orders_created + (obj.activity.orders_created?.count || 0),
                    candidateCreated: acc.candidateCreated + (obj.activity.candidateCreated?.count || 0),
                    candidateContacted: acc.candidateContacted + (obj.activity.candidateContacted?.count || 0),
                    candidateMeet: acc.candidateMeet + (obj.activity.candidateMeet?.count || 0),
                    candidateIntegrated: acc.candidateIntegrated + (obj.activity.candidateIntegrated?.count || 0),
                    candidateRefused: acc.candidateRefused + (obj.activity.candidateRefused?.count || 0),
                };
            }, this.totalStatisticsInit);
            if (this.statistics.length !== 0) {
                this.totalStatistics = {
                    ...this.totalStatistics,
                    download_rate: Math.round(this.totalStatistics.download_rate / this.statistics.length),
                };
            }
        } catch (error) {
            console.log(error);
        }
    }

    changeView(context: string) {
        this.innerValue = context;
    }

    showRanges() {
        this.opened = true;
        if (!this.timeout) {
            this.timeout = setTimeout(() => {
                this.$scrollTo(this.$refs["range-10"][0], 300, {
                    container: this.$refs.container.$el,
                    offset: -(this.$refs.container.$el.parentElement.scrollHeight / 2 - 20),
                });
                this.timeout = 0;
            }, 50);
        }
    }

    hideRanges() {
        this.opened = false;
        clearTimeout(this.timeout);
    }

    isSelectedRange(range: any) {
        return range.name === this.theRange;
    }

    selectRange(range: any) {
        this.filters.week = range.from.valueOf();
        this.opened = false;
        this.init();
    }

    formattedRange(range: any) {
        return `${range.from.format("DD MMMM")} - ${range.to.format("DD MMMM YY")}`;
    }

    subDate() {
        this.filters.week = moment(this.filters.week).subtract(1, "week").startOf("week").valueOf();
        this.init();
    }
    addDate() {
        this.filters.week = moment(this.filters.week).add(1, "week").startOf("week").valueOf();
        this.init();
    }

    beforeMount() {
        this.init();
    }

    async handleDownloadClick() {
        const response = await this.downloadStatistics(this.filters);
        const a = document.createElement("a");
        a.download = "supervisor_stats_" + moment(this.filters.week).format("YYYY-MM-DD") + ".xlsx";
        a.href = "data:application/octet-stream;base64," + response;
        a.click();
    }

    get columnHeaders() {
        return [
            {
                label: "VIVIER ACTIF",
                value: "activePool",
                total: this.totalStatistics.activeWorkers,
            },
            {
                label: "VIVIER INACTIF",
                value: "inactivePool",
                total: this.totalStatistics.inactiveWorkers,
            },
            {
                label: "CANDIDATS RECRUTÉS",
                value: "candidatesRecruited",
                total: this.totalStatistics.workersHiredByWeek,
            },
            {
                label: "CANDIDATS INSCRITS VIA WIZIM",
                value: "candidatesRegisteredViaWizim",
                total: this.totalStatistics.workersHiredByWeekFromWizim,
            },
            {
                label: "UTILISATEURS DE L'APP",
                value: "usersOfTheApp",
                total: this.totalStatistics.WorkersUseApp,
            },
            {
                label: "TAUX DE TÉLÉCHAR..",
                value: "downloadRate",
                total: this.totalStatistics.download_rate + "%",
            },
            {
                label: "COMMANDES CRÉÉES",
                value: "OrdersCreated",
                total: this.totalStatistics.orders_created,
            },
        ];
    }

    get columnHeadersAts() {
        return [
            {
                label: "CANDIDATS AJOUTÉS DANS L'ATS",
                value: "candidatesAddedToATS",
                total: this.totalStatistics.candidateCreated,
            },
            {
                label: "CANDIDATS CONTACTÉS",
                value: "candidatesContacted",
                total: this.totalStatistics.candidateContacted,
            },
            {
                label: "CANDIDATS REÇUS EN ENTRETIEN",
                value: "candidatesInterviewed",
                total: this.totalStatistics.candidateMeet,
            },
            {
                label: "CANDIDATS INTÉGRÉS AU VIVIER",
                value: "candidatesIntegrated",
                total: this.totalStatistics.candidateIntegrated,
            },
            {
                label: "CANDIDATS REFUSÉS",
                value: "candidatesRefused",
                total: this.totalStatistics.candidateRefused,
            },
        ];
    }
}
